import React from 'react';
import Container from '../components/Container';

export default function Page({ pageContext: { data } }) {
	return (
		<Container>
			<div className='d-flex flex-column rounded-3 shadow bg-white overflow-hidden'>
				{data.featuredImage?.node?.mediaItemUrl && (
					<div
						className='w-100 overflow-hidden d-flex'
						style={{ maxHeight: '20rem' }}
					>
						<img
							className='img-fluid w-100 align-self-center'
							src={data.featuredImage.node.mediaItemUrl}
							alt=''
						/>
					</div>
				)}
				<h1 className='p-4 mb-0 border-bottom'>{data.title}</h1>
				<div
					className='p-4 '
					dangerouslySetInnerHTML={{ __html: data.content }}
				></div>
			</div>
		</Container>
	);
}
